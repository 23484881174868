import React,{useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Logo from '../assets/image/logo.svg';
import { GiHamburgerMenu } from "react-icons/gi"


const Navbar = () => {
    useEffect(() => {
        AOS.init();
      }, [])
   return(
    <>
        <header data-aos="fade-down">
            <div className="container">
                <div className="wrapper-header">
                    <div className="logo">
                        <img 
                            src={Logo} 
                            alt="" 
                        />
                    </div>
                    
                    <div className="nav-menu">
                        <ul>
                            <li><a href="">About me</a></li>
                            <li><a href="">Portfolio</a></li>
                            <li><a href="">Case Studeis</a></li>
                            <li><a href="">Insights</a></li>
                        </ul>
                    </div>
                    

                    <div className="right-connectBtn">
                        <button className="border-btn">Let's Connect</button>
                    </div>
                </div>
            </div>
        </header>
    </>
   );
}

export default Navbar;
