import { motion, useTransform, useScroll } from "framer-motion";
import { useRef } from "react";
import Project2 from '../assets/image/project1.png';

const Portfolio = () => {
  return (
    <div className="">
      
      <HorizontalScrollCarousel />
      <div className="flex h-48 items-center justify-center">
        <span className="font-semibold uppercase text-neutral-500">
          Scroll up
        </span>
      </div>
    </div>
  );
};

const HorizontalScrollCarousel = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const x = useTransform(scrollYProgress, [0, 1], ["1%", "-50%"]);

  return (
    <section ref={targetRef} className="relative h-[300vh] ">
      <div className="sticky top-0 flex h-screen items-center overflow-hidden">
        <motion.div style={{ x }} className="flex gap-4">
          {cards.map((card) => {
            return <Card card={card} key={card.id} />;
          })}
        </motion.div>
      </div>
    </section>
  );
};

const Card = ({ card }: { card: CardType }) => {
    return (
      <div
        key={card.id}
        className="group relative h-[450px] w-[450px] overflow-hidden "
      >
        <div
          style={{
            backgroundImage: `url(${card.url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height:"600px",
            width: "600px"
          }}
          className="absolute inset-0 z-0 transition-transform duration-300 group-hover:scale-110"
        ></div>
        <div className="absolute inset-0 z-10 grid place-content-center">
          <p className="bg-gradient-to-br from-white/20 to-white/0 p-8 text-6xl font-black uppercase text-white backdrop-blur-lg">
            {card.title}
          </p>
        </div>
      </div>
    );
  };
  

export default Portfolio;
type CardType = {
    url: string;
    title: string;
    id: number;
  };
  const cards: CardType[] = [
  {
    url: "https://picsum.photos/id/1018/300/200",
    title: "Title 1",
    id: 1,
  },
  {
    url: "https://picsum.photos/id/1018/300/200",
    title: "Title 2",
    id: 2,
  },
  {
    url: "https://picsum.photos/id/1018/300/200",
    title: "Title 3",
    id: 3,
  },
  {
    url: "https://picsum.photos/id/1018/300/200",
    title: "Title 4",
    id: 4,
  },
  {
    url: "https://picsum.photos/id/1018/300/200",
    title: "Title 5",
    id: 5,
  },
  {
    url: "https://picsum.photos/id/1018/300/200",
    title: "Title 6",
    id: 6,
  },
  {
    url: "https://picsum.photos/id/1018/300/200",
    title: "Title 7",
    id: 7,
  },
];