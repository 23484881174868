
import React from "react";
import { Helmet } from "react-helmet";
import './bootstrap.css';
import './App.scss';

import Navbar from './components/Navbar';

import Hero from './components/Hero';

import Portfolio from './components/Portfolio';

// import About from './components/About';

// import Ecosystem from './components/Ecosystem';

// import Gallery from './components/Gallery';

// import Team from './components/Team';

// import Contact from './components/Contact';

// import Footer from './components/Footer';
function App() {
  return (
    <div>
      <Helmet>
        <title>Sujam Shrestha - Creative Head, Graphic & UI/UX Designer</title>
        <meta charset="UTF-8" />
        <meta name="description" content="Sujam Shrestha, a Creative Head with over 11 years of experience in graphic design, product design, logo design, UI/UX design, and mentoring. Engaged with Swivt Technologies." />
        <meta name="keywords" content="Sujam Shrestha, Graphic Design, Product Design, Logo Design, UI/UX Design, Swivt Technologies, Mentor, Creative Head" />
        <meta name="author" content="Sujam Shrestha" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta property="og:title" content="Sujam Shrestha - Creative Head, Designer & Mentor" />
        <meta property="og:description" content="Explore the portfolio and work of Sujam Shrestha, a Creative Head with expertise in graphic design, product design, and UI/UX design." />
        <meta property="og:image" content="URL to your portfolio image" />
        <meta property="og:url" content="https://suzamb.com.np" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Sujam Shrestha - Creative Head & Design Mentor" />
        <meta name="twitter:description" content="With 11+ years of experience, Sujam Shrestha is a creative head specializing in design leadership and mentoring." />
        <meta name="twitter:image" content="URL to your portfolio image" />
        <link rel="apple-touch-icon" href="/logo192.png" />
        <link rel="manifest" href="/manifest.json" />
      </Helmet>
      <Navbar />
      <Hero />
      <Portfolio />

    </div>
  );
}



export default App;
